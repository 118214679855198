import { Image } from "artisn-ui-react";
import React, { useRef } from "react";

import Styles from "./AdsBannerImage.styles";
import { AdsBannerImageProps as Props } from "./AdsBannerImage.types";
import useObserveBanners from "hooks/useObserveBanners";

const AdsBannerImage: React.FC<Props> = props => {
  const { banner, handleBannerClick } = props;
  const viewRef = useRef(null);

  const { image_url: img, banner_title: title, banner_type } = banner;
  const isClickable = banner_type !== "INFO";
  const imageClass = isClickable
    ? "AdsBannerImage__banner__image"
    : "AdsBanner__banner__read";

  useObserveBanners(banner, viewRef, "view_ads_banner");

  const handleClick = () => {
    if (!isClickable) return;
    handleBannerClick(banner);
  };

  return (
    <Styles ref={viewRef} className="AdsBannerImage">
      <Image
        className={`${imageClass}`}
        image={img}
        alt={title}
        placeholder="skeleton"
        onClick={handleClick}
      />
    </Styles>
  );
};

export default AdsBannerImage;
