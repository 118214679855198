import styled from "styled-components";

import { TagStyledProps as Props } from "./Tag.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const TagStyled = styled.div<Props>`
  display: flex;
  background-color: ${props => props.badgeBackgroundColor};
  padding: 0.6rem 1.2rem;
  border-radius: 0.8rem;
  width: fit-content;
  margin-right: 0.8rem;
  align-items: center;

  @media (max-width: ${tablet}px) {
    padding: 0.4rem 0.8rem;
    margin-bottom: 0;
  }

  .Tag {
    &__icon {
      margin-right: 0.4rem;
      height: auto;

      path {
        fill: var(--palette-white);
      }
    }

    &__text {
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 600;
      color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        font-size: 1.2rem;
      }
    }
  }
`;

export default TagStyled;
