import CONSTANTS from "config/constants";

const { wide, desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

export const responsive = {
  wide: {
    breakpoint: { max: Infinity, min: wide },
    items: 2
  },
  desktop: {
    breakpoint: { max: wide, min: desktop },
    items: 2
  },
  tablet: {
    breakpoint: { max: desktop, min: tablet },
    items: 1
  },
  mobile: {
    breakpoint: { max: tablet, min: mobile },
    items: 1
  },
  smaller: {
    breakpoint: { max: mobile, min: 0 },
    items: 1
  }
};
