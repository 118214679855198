import styled from "styled-components";

import { SpotlightTabsMenuItemStyledProps as Props } from "./SpotlightTabsMenuItem.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SpotlightTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: ${props => (props.active ? "var(--palette-primary)" : "")};
  }

  && .TabsMenuItem__title {
    color: var(--palette-white);
    padding-left: 0.4rem;

    @media (max-width: ${tablet}px) {
      padding-left: 0;
      color: ${props =>
        props.active
          ? "var(--palette-primary)"
          : "var(--palette-light-blue-s10-l60)"};
    }
  }

  .SpotlightTabsMenuItem {
    &__item {
      flex-direction: row;
      color: var(--palette-white);
      padding: 0.8rem 2rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 1.2rem;
      font-weight: 700;
      line-height: 0.8rem;

      @media (max-width: ${tablet}px) {
        background-color: transparent;
        flex-direction: column;
        font-weight: 400;
        line-height: 1.2rem;
        padding: 0;

        path {
          fill: ${props =>
            props.active
              ? "var(--palette-primary)"
              : "var(--palette-light-blue-s10-l60)"};
        }
      }
    }
  }
`;

export default SpotlightTabsMenuItemStyled;
