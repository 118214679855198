import styled from "styled-components";

import { MoviesCarouselStyledProps as Props } from "./MoviesCarousel.types";

const MoviesCarouselStyled = styled.section<Props>`
  padding-bottom: 4.2rem;

  .MoviesCarousel {
    &__container {
      width: 100%;
      z-index: 0;
      padding-bottom: 0.8rem;

      .react-multi-carousel-track {
        width: 100%;
        max-height: 72rem;
      }

      .react-multiple-carousel__arrow {
        background: transparent;
        min-width: max-content;
        bottom: -0.8rem;

        &--right {
          left: 12.8rem;
          right: auto;
        }

        &--right::before {
          content: "";
        }

        &--left {
          left: 0;
        }
      }
    }

    &__item {
      min-width: 100%;
    }

    &__dots {
      background-color: var(--palette-gray-s0-l30-a40);
      align-items: center;
      justify-content: center;
      padding: 1.2rem 0.8rem;
      min-width: 15.2rem;
      width: max-content;
      border-radius: 3.2rem;

      & li {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & li button {
        width: 0.8rem;
        height: 0.8rem;
        border: none;
        background-color: var(--palette-light-blue-s10-l60);
      }

      .react-multi-carousel-dot--active {
        & button {
          background-color: var(--palette-white);
        }
      }
    }
  }
`;

export default MoviesCarouselStyled;
