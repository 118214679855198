import { useFetchMovies, useFetchPresale } from "@multicines/services";
import { useFetchFilteredMovies } from "@multicines/services";
import { useCountriesStore } from "@multicines/stores";
import { useMoviesStore } from "@multicines/stores";
import { useRouter } from "next/router";
import React, { useCallback, useMemo } from "react";

import Styles from "./PresaleBanner.styles";
import { PresaleBannerProps as Props } from "./PresaleBanner.types";
import MoviesCarouselPlaceholder from "../MoviesCarousel/MoviesCarouselPlaceholder/MoviesCarouselPlaceholder";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const PresaleBanner: React.FC<Props> = props => {
  const { push } = useRouter();
  const t = useI18n().movie;

  const selectedCity = useCountriesStore(state => state.selectedCity);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const setSelectedMovie = useMoviesStore(store => store.setSelectedMovie);

  const { data: movies, isFetching } = useFetchMovies(
    { isAnonymous, uid },
    selectedStore
  );
  const { data: previewMovies } = useFetchFilteredMovies(
    { isAnonymous, uid },
    "Preview",
    selectedStore
  );

  const ticketsStore = selectedStore?.[0];
  const { externalId } = ticketsStore?.additionalInfo ?? {};
  const { data: presale } = useFetchPresale(externalId!, { isAnonymous, uid });
  const [presaleContent] = presale ?? [];
  const { movieExternalId } = presaleContent ?? {};

  const groupedMovies = useMemo(() => {
    if (!previewMovies) return [];
    return [...(movies || []), ...previewMovies];
  }, [movies, previewMovies]);

  const clickHandler = useCallback(() => {
    if (!movieExternalId || !selectedCity || !selectedStore) return;

    const selectedMovie = groupedMovies.find(
      movie => movie.externalId === movieExternalId
    );
    const modality = selectedMovie?.modality;

    setSelectedMovie(selectedMovie);

    push(
      `/movie/${movieExternalId}?modality=${modality}&cityId=${selectedCity.id}&storeId=${selectedStore[0]?.storeId}`
    );
  }, [
    movieExternalId,
    selectedCity,
    selectedStore,
    groupedMovies,
    setSelectedMovie,
    push
  ]);

  if (isFetching)
    return (
      <Styles className="PresaleBanner">
        <MoviesCarouselPlaceholder />
      </Styles>
    );

  if (!presale || presale.length === 0) {
    return null;
  }

  return (
    <Styles className="PresaleBanner" onClick={clickHandler}>
      <div className="PresaleBanner__header">
        <div className="PresaleBanner__animated">
          <div className="PresaleBanner__animated__line PresaleBanner__animated__line-medium"></div>
          <div className="PresaleBanner__animated__line PresaleBanner__animated__line-large"></div>
          <div className="PresaleBanner__animated__line PresaleBanner__animated__line-small"></div>
        </div>
        {t.exclusivePresale}
      </div>
      <div className="PresaleBanner__imageContainer">
        <img
          className="PresaleBanner__image-desktop"
          src={presaleContent?.webUrl}
          alt="Imagen"
        />
        <img
          className="PresaleBanner__image-mobile"
          src={presaleContent?.appUrl}
          alt="Imagen"
        />
      </div>
    </Styles>
  );
};

export default PresaleBanner;
