import React from "react";
import { animated } from "react-spring";

import Styles from "./BannerPlaceholder.styles";
import { BannerPlaceholderProps as Props } from "./BannerPlaceholder.types";
import usePlaceholderAnimation from "hooks/usePlaceholderAnimation";

const BannerPlaceholder: React.FC<Props> = props => {
  const { className } = props;
  const animationStyle = usePlaceholderAnimation();

  return (
    <Styles className={`BannerPlaceholder ${className}`}>
      <animated.div
        className="BannerPlaceholder__image"
        style={animationStyle}
      />
      <animated.div
        className="BannerPlaceholder__image"
        style={animationStyle}
      />
    </Styles>
  );
};

BannerPlaceholder.defaultProps = {};

export default BannerPlaceholder;
