import { AGES, transformToHours } from "@multicines/utils";
import { Image } from "artisn-ui-react";
import ClockSVG from "images/clock.svg";
import React, { useMemo, useRef, useState } from "react";

import Styles from "./MovieCard.styles";
import { MovieCardProps as Props } from "./MovieCard.types";
import Tag from "components/global/Tag/Tag";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";
import useWindowSize from "hooks/useWindowSize";

const { mobile } = CONSTANTS.BREAKPOINTS;
const { ALL_PUBLIC, WITHOUT_SUPERVISION } = AGES;
const { WITH_RESTRICTION, WITH_SUPERVISION } = AGES;

const MovieCard: React.FC<Props> = props => {
  const { className, movie, withLabels = true, isSelected, onClick } = props;
  const { isTalkShop, navigateWithTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const { width: windowWidth } = useWindowSize();
  const t = useI18n();
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { logSelectMovie } = analyticsHook;

  const isMobile = windowWidth <= mobile;
  const { imageUrl, title, modality, ageRestriction, externalId } = movie;
  const { duration, pointsRestricted, languages } = movie;
  const { age } = ageRestriction ?? {};

  const movieDuration = useMemo(() => {
    const time = transformToHours(duration ?? 0)
      .replace(":", "h ")
      .replace(/^0/, "");
    return `${time}min`;
  }, [duration]);
  const allPublic = age === ALL_PUBLIC;

  const clickHandler = () => {
    if (isSelected) return;
    logSelectMovie();
    onClick?.();

    const modalityFormat = !modality ? "All" : modality;
    navigateWithTalkShop(`/movie/${externalId}`, {
      modality: modalityFormat
    });
  };

  const renderAgeRestriction = () => {
    if (allPublic) {
      return <p className="MovieCard__description__age">{t.movie.allPublic}</p>;
    }
    if (!age) return;

    if (age < WITH_SUPERVISION) {
      return (
        <p className="MovieCard__description__age">{`${age} ${t.movie.withSupervision}`}</p>
      );
    }
    if (age === WITHOUT_SUPERVISION) {
      return (
        <p className="MovieCard__description__age">
          {`+${age}`} {t.movie.age}
        </p>
      );
    }
    if (age > WITH_RESTRICTION) {
      return (
        <p className="MovieCard__description__age__restricted">
          {`+${age}`} {t.movie.age}
        </p>
      );
    }
    return (
      <p className="MovieCard__description__age">
        {`+${age}`} {t.movie.age}
      </p>
    );
  };

  return (
    <Styles
      className={`MovieCard ${className}`}
      ref={wrapperRef}
      onClick={clickHandler}
      isSelected={isSelected}
    >
      {imageUrl && !errorLoadingImage ? (
        <Image
          className="MovieCard__icon-image"
          image={imageUrl}
          alt={imageUrl}
          height={isMobile ? 240 : 280}
          width={isMobile ? 160 : 184}
          onError={() => setErrorLoadingImage(true)}
        />
      ) : (
        <div className="MovieCard__image">
          <p className="MovieCard__placeholder">{title}</p>
        </div>
      )}

      {withLabels || pointsRestricted ? (
        <>
          <div className="MovieCard__info">
            {withLabels ? (
              <Tag modality={modality} ageRestriction={ageRestriction} />
            ) : null}
            {pointsRestricted && !isSelected ? (
              <div className="MovieCard__points">
                <p className="MovieCard__points-restriction">R</p>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <div className="MovieCard__description">
        <div className="MovieCard__description__language">
          {languages.map((language, index) => {
            if (!language) return null;
            return (
              <span key={language}>
                {language ?? ""}
                {typeof age === "number" || index < languages.length - 1
                  ? " · "
                  : ""}
              </span>
            );
          })}
          {renderAgeRestriction()}
        </div>
      </div>
      <p className="MovieCard__title">{title}</p>
      {isSelected ? (
        <div className="MovieCard__duration">
          <ClockSVG />
          <p className="MovieCard__duration__text">{movieDuration} </p>
        </div>
      ) : null}
    </Styles>
  );
};

export default MovieCard;
