import styled from "styled-components";

import { BillboardCarouselStyledProps as Props } from "./BillboardCarousel.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const BillboardCarouselStyled = styled.div<Props>`
  .BillboardCarousel {
    &__hello {
      color: var(--palette-gray-s0-l80);
      font-size: 2rem;
      font-weight: 700;
    }

    &__placeholder {
      display: grid;
      padding-left: 0;
      grid-template-columns: repeat(6, 24rem);
      padding-top: 3.2rem;
      width: auto;
      overflow: hidden;
    }

    &__item {
      display: flex;
      justify-content: center;
    }

    &__container {
      padding-top: 3.2rem;
      z-index: 0;

      .react-multiple-carousel__arrow {
        background: transparent;
        top: 0;

        &--right {
          right: 0;
        }

        &--left {
          right: calc(4% + 1px);
          left: auto;

          @media (max-width: ${tablet}px) {
            right: calc(8% + 1px);
          }
        }
      }
    }

    &__movies {
      .MovieCard {
        &__title {
          font-size: 1.4rem;
          text-align: start;
          max-width: 18rem;
        }

        &__description {
          margin-top: 0.4rem;
          margin-bottom: 0;
        }
      }
    }

    &__line {
      width: 100%;
      height: 0.1rem;
      background-color: var(--palette-mid-blue-s15-l20);
    }

    &__divider {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4rem;
    }

    &__empty {
      padding: ${props => (props.empty ? "4rem 0" : "0")};
    }

    &__button {
      color: var(--palette-primary);
      background-color: var(--palette-mid-blue-s15-l20);
      padding: 1rem 2rem;
    }
  }
`;

export default BillboardCarouselStyled;
