import { useFetchBanners } from "@multicines/services";
import { SNACKS_VENDOR } from "@multicines/utils";
import { Banner } from "artisn/types";
import Link from "next/link";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";

import { responsive } from "./AdsBanner.helpers";
import Styles from "./AdsBanner.styles";
import { AdsBannerProps as Props } from "./AdsBanner.types";
import AdsBannerImage from "./AdsBannerImage/AdsBannerImage";
import BannerPlaceholder from "../BannerPlaceholder/BannerPlaceholder";
import ProductModal from "components/global/ProductModal/ProductModal";
import useAnalytics from "hooks/analytics/useAnalytics";
import { useStoresStore } from "stores/stores/stores.store";
import { sanitizeText } from "utils/seo.utils";

const AdsBanner: React.FC<Props> = props => {
  const { className } = props;

  const analyticsHook = useAnalytics({});
  const [activeProductId, setActiveProductId] = useState<
    number | undefined | null
  >();
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [, snacksStore] = selectedStore ?? [];
  const { storeId, vendor: snackVendor } = snacksStore ?? {};
  const { id: selectedSnackVendorId } = snackVendor ?? {};
  const { data: banners, status } = useFetchBanners(
    "promos_home_banner",
    storeId,
    selectedSnackVendorId ?? SNACKS_VENDOR.id
  );

  const { logSelectAdsBanner } = analyticsHook;

  const handleBannerClick = (banner: Banner) => {
    const { banner_description, banner_title, banner_type } = banner;
    const { product_name, vendor_name, banner_category_id } = banner;

    logSelectAdsBanner({
      banner_category_id:
        banner_category_id ?? "banner without banner category id",
      banner_title: banner_title ?? "banner without banner title",
      banner_description:
        banner_description ?? "banner without banner description",
      banner_type: banner_type ?? "banner without banner type",
      product_name: product_name ?? "banner without product name",
      vendor_name: vendor_name ?? "banner without vendor name"
    });
  };

  const renderStates = () => {
    if (status === "loading" || status === "idle") {
      return <BannerPlaceholder className="AdsBanner__placeholder" />;
    }
    if (status === "success") {
      return (
        <Carousel
          swipeable
          draggable
          autoPlaySpeed={5000}
          showDots={false}
          infinite
          arrows={false}
          autoPlay
          responsive={responsive}
          keyBoardControl
          containerClass="AdsBanner__container"
          itemClass="AdsBanner__item"
        >
          {banners
            ?.filter(item => item.banner_title)
            .map((banner, index) => {
              return renderBanners(banner, index);
            })}
        </Carousel>
      );
    }
  };

  const renderBanners = (
    banner: Banner,
    index: React.Key | null | undefined
  ) => {
    const { banner_type } = banner;

    if (banner_type === "URL") {
      const url = banner.banner_url ?? " ";
      return (
        <a
          key={index}
          className="AdsBanner__banner"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <AdsBannerImage
            banner={banner}
            handleBannerClick={handleBannerClick}
          />
        </a>
      );
    }

    if (banner_type === "PRODUCT") {
      const { product_id } = banner;
      return (
        <div
          key={index}
          className="AdsBanner__banner"
          onClick={() => setActiveProductId(product_id)}
        >
          <AdsBannerImage
            banner={banner}
            handleBannerClick={handleBannerClick}
          />
        </div>
      );
    }

    if (banner_type === "CATEGORY") {
      const { category_id, category_name } = banner;
      const sanitizedName = sanitizeText(category_name ?? "");
      const url = category_id
        ? `/categories/${category_id}/${sanitizedName}`
        : "/categories";
      return (
        <div key={index} className="AdsBanner__banner">
          <Link key={index} href={url} passHref>
            <AdsBannerImage
              banner={banner}
              handleBannerClick={handleBannerClick}
            />
          </Link>
        </div>
      );
    }

    if (banner_type === "INFO") {
      return (
        <div key={index} className="AdsBanner__banner">
          <AdsBannerImage
            banner={banner}
            handleBannerClick={handleBannerClick}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Styles className={`AdsBanner ${className} `}>
      {renderStates()}
      <ProductModal
        isOpen={!!activeProductId}
        onClose={() => setActiveProductId(undefined)}
        productId={`${activeProductId ?? ""}`}
      />
    </Styles>
  );
};

export default AdsBanner;
