import styled from "styled-components";

import { TabsMenuStyledProps as Props } from "./TabsMenu.types";

const TabsMenuStyled = styled.div<Props>`
  &&& .TabsMenu__bar {
    padding-bottom: 0.8rem;
    background-color: var(--palette-black);
  }

  .TabsMenu {
    &__active {
      .ActiveTicket {
        &__background {
          transform: rotate(180deg) translateX(1rem);
        }
      }
    }

    &__tabs {
      &__item {
        min-width: 6rem;
      }
    }
  }
`;

export default TabsMenuStyled;
