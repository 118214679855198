import { useSpring, config } from "react-spring";

import variables from "styles/util/variables";

const { palette } = variables;

const usePlaceholderAnimation = () => {
  return useSpring({
    from: { backgroundColor: palette["mid-blue-s15-l10"] },
    to: async next => {
      await next({ backgroundColor: palette["mid-blue-s15-l20"] });
      await next({ backgroundColor: palette["mid-blue-s15-l10"] });
    },
    loop: true,
    config: config.gentle
  });
};

export default usePlaceholderAnimation;
