import styled from "styled-components";

import { BannerPlaceholderStyledProps as Props } from "./BannerPlaceholder.types";
import CONSTANTS from "config/constants";

const { tablet, desktop } = CONSTANTS.BREAKPOINTS;

const BannerPlaceholderStyled = styled.div<Props>`
  display: flex;
  flex: 1;

  .BannerPlaceholder {
    &__image {
      width: 100%;
      border-radius: 0.4rem;
      height: 36rem;
      margin: 0 4rem;

      @media (max-width: ${desktop}px) {
        margin: 0 18rem;

        &:not(:first-child) {
          display: none;
        }
      }

      @media (max-width: ${tablet}px) {
        margin: 0 4rem;
      }

      @media (max-width: ${tablet - 1}px) {
        margin: 0;
        height: 18rem;
      }
    }
  }
`;

export default BannerPlaceholderStyled;
