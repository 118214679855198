import { Movie } from "@multicines/types";

import variables from "styles/util/variables";

const { palette } = variables;

export const getModalityBGColor = (modality: Movie["modality"]): string => {
  if (!modality) return "";
  switch (modality) {
    case "Premiere":
      return palette["pink-s75-l65"];
    case "Presale":
      return palette["red-s65-l60"];
    case "Preview":
      return palette["blue-s100-l45"];
    default:
      throw new Error(`Modality not handled: ${modality}`);
  }
};
