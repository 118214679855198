import styled from "styled-components";

import { BillboardPlaceholderStyledProps as Props } from "./BillboardPlaceholder.types";
import CONSTANTS from "config/constants";

const { mobile, desktop } = CONSTANTS.BREAKPOINTS;

const BillboardPlaceholderStyled = styled.div<Props>`
  display: flex;
  flex: 1;
  padding: 0 2.4rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .BillboardPlaceholder {
    &__card {
      margin-bottom: 3.2rem;
      padding: 0 2.4rem;

      @media (max-width: ${mobile}px) {
        width: 100%;
      }
    }

    &__image {
      height: 24rem;
      border-radius: 0.4rem;

      @media (max-width: ${desktop}px) {
        min-width: 16rem;
      }

      @media (max-width: ${mobile}px) {
        width: auto;
      }
    }

    &__modality {
      width: 8rem;
      height: 2.4rem;
      margin: 0.4rem 0;
      border-radius: 0.8rem;
    }

    &__language {
      width: 6.4rem;
      margin: 0.4rem 0;
      height: 1.6rem;
      border-radius: 0.8rem;
    }

    &__title {
      margin: 0.4rem 0;
      height: 2rem;
      border-radius: 0.8rem;
    }
  }
`;

export default BillboardPlaceholderStyled;
