import React from "react";
import { animated } from "react-spring";

import Styles from "./MoviesCarouselPlaceholder.styles";
import { MoviesCarouselPlaceholderProps as Props } from "./MoviesCarouselPlaceholder.types";
import usePlaceholderAnimation from "hooks/usePlaceholderAnimation";

const MoviesCarouselPlaceholder: React.FC<Props> = props => {
  const animationStyle = usePlaceholderAnimation();

  return (
    <Styles className="MoviesCarouselPlaceholder">
      <animated.div
        className="MoviesCarouselPlaceholder__container"
        style={animationStyle}
      />
    </Styles>
  );
};

MoviesCarouselPlaceholder.defaultProps = {};

export default MoviesCarouselPlaceholder;
