import { Image } from "artisn-ui-react";
import AppleSVG from "images/apple-filled.svg";
import DownloadBackgroundSVG from "images/background-download.svg";
import PlaySVG from "images/play-filled.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./DownloadBanner.styles";
import { DownloadBannerProps as Props } from "./DownloadBanner.types";
import Button from "../Button/Button";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import { isIOS } from "utils/common.utils";

const { APP_STORE_URL, PLAY_STORE_URL } = CONSTANTS.GENERAL;

const DownloadBanner: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();
  const t = useI18n().downloadBanner;

  const redirectToAppStoreOrPlayStore = () => {
    if (isIOS()) {
      push(APP_STORE_URL);
      return;
    }
    push(PLAY_STORE_URL);
  };

  return (
    <Styles className={`DownloadBanner ${className}`}>
      <div className="DownloadBanner__container">
        <div className="DownloadBanner__body">
          <p className="DownloadBanner__text">{t.title}</p>
          <Button
            className="DownloadBanner__button"
            mode="PRIMARY"
            onClick={redirectToAppStoreOrPlayStore}
          >
            <PlaySVG />
            <AppleSVG />
            <p className="DownloadBanner__text__mobile">{t.download}</p>
            <p className="DownloadBanner__text__desktop">{t.downloadApp}</p>
          </Button>
        </div>
        <div className="DownloadBanner__image">
          <Image
            className="DownloadBanner__image-desktop"
            alt="image"
            image="assets/images/download-banner.png"
          />
          <Image
            className="DownloadBanner__image-mobile"
            alt="image"
            image="assets/images/download-banner-small.png"
          />
          <DownloadBackgroundSVG className="DownloadBanner__svg" />
        </div>
      </div>
    </Styles>
  );
};

DownloadBanner.defaultProps = {
  className: ""
};

export default DownloadBanner;
