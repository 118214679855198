import styled from "styled-components";

import { AdsBannerStyledProps as Props } from "./AdsBanner.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const AdsBannerStyled = styled.div<Props>`
  .AdsBanner {
    &__banner {
      &__image {
        .Image__img {
          cursor: pointer;
          aspect-ratio: 16/9;
          border-radius: 1.6rem;
          object-fit: cover;
        }
      }

      &__read {
        .Image__img {
          cursor: default;
        }
      }
    }

    &__container {
      padding-top: 4rem;
      z-index: 0;

      .react-multi-carousel-track {
        min-height: 40rem;

        @media (max-width: ${tablet - 1}px) {
          min-height: 16rem;
        }
      }

      .react-multiple-carousel__arrow {
        background: transparent;
        top: 0;

        &--right {
          right: 0;
        }

        &--left {
          right: calc(4% + 1px);
          left: auto;

          @media (max-width: ${tablet}px) {
            right: calc(8% + 1px);
          }
        }
      }
    }

    &__item {
      display: flex;
      justify-content: center;
      padding: 0 1.6rem;
    }

    &__placeholder {
      padding-top: 4rem;
    }
  }
`;

export default AdsBannerStyled;
