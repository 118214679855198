import styled from "styled-components";

import { DownloadBannerStyledProps as Props } from "./DownloadBanner.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const DownloadBannerStyled = styled.div<Props>`
  align-self: center;
  background-color: var(--palette-mid-blue-s15-l8);
  margin-top: auto;
  border-radius: 1.8rem;
  background-image: url(assets/images/bg-colors.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  width: 100%;
  position: relative;

  .DownloadBanner {
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &__body {
      display: grid;
      row-gap: 1.6rem;
      z-index: 1;

      @media (max-width: ${tablet}px) {
        padding: 3.2rem;
        padding-right: 0;
        padding-left: 1.6rem;
      }

      @media (max-width: ${tablet - 1}px) {
        max-width: 16rem;
      }
    }

    &__image {
      position: relative;
    }

    &__image-desktop {
      z-index: 1;

      @media (max-width: ${tablet - 1}px) {
        display: none;
      }
    }

    &__image-mobile {
      display: none;

      @media (max-width: ${tablet - 1}px) {
        display: flex;

        .Image {
          &__img {
            max-width: 16rem;
            min-height: 16rem;
          }
        }
      }
    }

    &__text {
      color: var(--palette-white);
      font-size: 2rem;

      @media (max-width: ${tablet - 1}px) {
        font-size: 1.6rem;
      }

      &__mobile {
        @media (min-width: ${tablet}px) {
          display: none;
        }
      }

      &__desktop {
        @media (max-width: ${tablet - 1}px) {
          display: none;
        }
      }
    }

    &__button {
      width: max-content;
      height: 4.8rem;
      color: var(--palette-black);
      font-weight: 700;
      font-size: 1.6rem;
    }

    &__svg {
      z-index: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: ${tablet - 1}px) {
        display: none;
      }
    }
  }
`;

export default DownloadBannerStyled;
