import StarSVG from "images/star.svg";
import TicketSVG from "images/ticket.svg";
import React from "react";

import { getModalityBGColor } from "./Tag.helpers";
import Styles from "./Tag.styles";
import { TagProps as Props } from "./Tag.types";
import useI18n from "hooks/useI18n";

const Tag: React.FC<Props> = props => {
  const { className, modality } = props;
  const badgeBackgroundColor = getModalityBGColor(modality);
  const t = useI18n();

  if (!modality) return null;

  return (
    <Styles
      className={`Tag ${className}`}
      badgeBackgroundColor={badgeBackgroundColor}
    >
      {modality === "Presale" ? (
        <>
          <TicketSVG className="Tag__icon" />
          <p className="Tag__text Tag__modalityText">{t.movie.presale}</p>
        </>
      ) : (
        <>
          <StarSVG className="Tag__icon" />
          <p className="Tag__text Tag__modalityText">
            {modality === "Premiere" ? t.movie.premiere : t.movie.preview}
          </p>
        </>
      )}
    </Styles>
  );
};

Tag.defaultProps = {
  className: ""
};

export default Tag;
