import styled from "styled-components";

import { PresaleBannerStyledProps as Props } from "./PresaleBanner.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const PresaleBannerStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  height: 200px;
  height: fit-content;
  border-radius: 3rem;
  margin-bottom: 2.4rem;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    hsla(36, 100%, 71%, 1) 0%,
    hsla(44, 75%, 62%, 1) 10%,
    hsla(41, 97%, 54%, 0) 30%
  );

  .PresaleBanner {
    &__header {
      position: absolute;
      top: -2rem;
      padding: 1rem;
      width: 40%;
      text-align: center;
      border-radius: 10rem;
      background: linear-gradient(
        to right,
        hsla(49, 100%, 49%, 1),
        hsla(39, 100%, 50%, 1)
      );
      overflow: hidden;
      z-index: 1;

      @media (max-width: ${mobile - 1}px) {
        width: 60%;
      }
    }

    &__imageContainer {
      display: flex;
      justify-content: center;
      width: 99.8%;
      margin: 0.2rem 0.1rem;
      background-color: var(--palette-mid-blue-s15-l8);
      height: fit-content;
      border-radius: 3rem;
      border: 1.6rem solid var(--palette-mid-blue-s15-l8);

      @media (max-width: ${tablet - 1}px) {
        width: 99%;
      }
    }

    &__animated {
      position: absolute;
      top: -0.5rem;
      left: 0;
      bottom: 0;
      display: flex;
      width: 100%;
      gap: 10px;
      animation: moveRightLeft 4s linear infinite;
      z-index: -1;
      &__line {
        height: 160%;
        rotate: 23deg;
        background-color: hsla(47, 93%, 84%, 0.5);
        &-small {
          width: 4px;
        }
        &-medium {
          width: 8px;
        }

        &-large {
          width: 20px;
        }
      }
    }

    &__image-desktop {
      width: 100%;
      border-radius: 3rem;
      margin-top: 0.8rem;

      @media (width <= ${tablet}px) {
        display: none;
      }
    }

    &__image-mobile {
      display: none;
      margin-bottom: 1.6rem;

      @media (width <= ${tablet}px) {
        display: block;
        width: 100%;
        aspect-ratio: 4/5;
        margin-top: 0.8rem;
      }
    }
  }

  @keyframes moveRightLeft {
    0%,
    100% {
      transform: translateX(-10%);
    }
    50% {
      transform: translateX(100%);
    }
  }
`;

export default PresaleBannerStyled;
