import styled from "styled-components";

import { MovieCarouselBannerStyledProps as Props } from "./MovieCarouselBanner.types";
import CONSTANTS from "config/constants";

const { hd, tablet, mobile } = CONSTANTS.BREAKPOINTS;

const MovieCarouselBannerStyled = styled.div<Props>`
  cursor: pointer;

  .MovieCarouselBanner {
    &__images {
      z-index: -1;

      .Image {
        &__img {
          width: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 3.2rem;
          aspect-ratio: 97 / 33;

          @media (max-width: ${tablet - 1}px) {
            border-radius: 1.6rem;
          }

          @media (max-width: ${mobile - 1}px) {
            border-radius: 1.6rem;
            aspect-ratio: 284 / 331;
            height: auto;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: -2.4rem;

      &__buySvg {
        margin: 0.5rem;
      }

      &__buyText {
        font-weight: 700;
        font-size: 1.6rem;
        color: var(--palette-black-s0-l10);
        margin-right: 1.6rem;

        @media (max-width: ${tablet - 1}px) {
          font-size: 1.4rem;
          display: none;
        }

        @media (min-width: ${hd}px) {
          font-size: 2.4rem;
        }
      }

      /* TODO: uncomment when need */
      /* &__trailer {
        font-weight: 700;
        font-size: 1.6rem;

        & > a {
          color: var(--palette-primary);
          text-decoration: none;
          display: flex;
          align-items: center;

          & > svg {
            margin-right: 0.8rem;

            path {
              fill: var(--palette-primary);
            }
          }
        }

        &:hover:not([disabled]) {
          background-color: transparent;
          color: var(--palette-primary);
        }

        @media (max-width: ${tablet - 1}px) {
          font-size: 1.4rem;
        }

        @media (min-width: ${hd}px) {
          font-size: 2.4rem;
        }
      } */
    }
  }
`;

export default MovieCarouselBannerStyled;
