import styled from "styled-components";

import { MoviesCarouselPlaceholderStyledProps as Props } from "./MoviesCarouselPlaceholder.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const MoviesCarouselPlaceholderStyled = styled.div<Props>`
  height: 100%;
  width: 100%;

  .MoviesCarouselPlaceholder {
    &__container {
      width: 100%;
      height: 100%;
      border-radius: 3.2rem;
      padding-top: 34.1%;
      padding-bottom: 3.6rem;

      @media (max-width: ${mobile - 1}px) {
        border-radius: 1.6rem;
        padding-top: 116.3%;
      }
    }
  }
`;

export default MoviesCarouselPlaceholderStyled;
