import { useFetchUser } from "@multicines/services";
import { CINEMARK_TICKETS_VENDOR } from "@multicines/utils";
import { Modal } from "artisn-ui-react";
import CloseSVG from "images/close.svg";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

import Styles from "./Home.styles";
import { HomeProps as Props } from "./Home.types";
import DownloadBanner from "../../global/DownloadBanner/DownloadBanner";
import AdsBanner from "../AdsBanner/AdsBanner";
import BillboardCarousel from "../BillboardCarousel/BillboardCarousel";
import MembershipModal from "../MembershipModal/MembershipModal";
import MoviesCarousel from "../MoviesCarousel/MoviesCarousel";
import PresaleBanner from "../PresaleBanner/PresaleBanner";
import WelcomeHome from "../WelcomeHome/WelcomeHome";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { createBenefitsNotification } from "utils/notifications.utils";
import { dismissBenefitsNotification } from "utils/notifications.utils";

const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;
const TICKET_CINEMARK_ID = CINEMARK_TICKETS_VENDOR.id;

const Home: React.FC<Props> = props => {
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { push } = useRouter();
  const { data: user } = useFetchUser({ isAnonymous, uid });
  const clubName = useAuthStore(state => state.clubName);

  const selectedStore = useStoresStore(store => store.selectedStore);
  const t = useI18n();
  const [opened, setOpened] = useState(false);
  const loyaltyNotificationVisited = useRef(false);

  useRequestNavigatorGeo();

  const [ticketStore] = selectedStore ?? [];
  const { vendor } = ticketStore ?? {};
  const { id: ticketVendorId } = vendor ?? {};

  useEffect(() => {
    if (typeof isAnonymous === "undefined") return;

    createBenefitsNotification(
      t.auth.AuthNotification.benefits,
      t.auth.AuthNotification.signIn,
      0,
      () => {
        dismissBenefitsNotification();
        push("/signup");
      },
      isAnonymous
    );

    return () => {
      dismissBenefitsNotification();
    };
  }, [
    isAnonymous,
    push,
    t.auth.AuthNotification.benefits,
    t.auth.AuthNotification.signIn
  ]);

  useEffect(() => {
    if (!ticketVendorId) return;
    if (!TICKET_CINEMARK_ID) return;
    if (ticketVendorId === TICKET_CINEMARK_ID) return;

    const visited = loyaltyNotificationVisited.current;
    if ((WITH_LOYALTY && !!clubName) || isAnonymous || !user || visited) {
      return;
    }
    const timer = setTimeout(() => {
      loyaltyNotificationVisited.current = true;
      setOpened(true);
    }, 3 * 1000);

    return () => {
      clearTimeout(timer);
      setOpened(false);
    };
  }, [clubName, isAnonymous, user, ticketVendorId]);

  const closeHandler = () => {
    setOpened(false);
  };

  return (
    <Styles className="Home">
      <header className="Home__header">
        <Navbar showBrand />
      </header>
      <main className="Home__main">
        <WelcomeHome />
        <MoviesCarousel className="Home__movies" />
        <PresaleBanner />
        <BillboardCarousel className="Home__carousel" />
        <AdsBanner className="Home__ads" />
      </main>
      <DownloadBanner className="Home__download" />
      <footer className="Home__footer">
        <Footer />
      </footer>
      <Modal
        {...props}
        className="Home__modal"
        opened={opened}
        color="transparent"
        closeOnClickOutside={false}
        closeIcon={<CloseSVG />}
        onClose={closeHandler}
      >
        <MembershipModal />
      </Modal>
    </Styles>
  );
};

export default Home;
