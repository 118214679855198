import { useFetchUser } from "@multicines/services";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./WelcomeHome.styles";
import { WelcomeHomeProps as Props } from "./WelcomeHome.types";
import Button from "components/global/Button/Button";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";

const WelcomeHome: React.FC<Props> = props => {
  const { push } = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { data: user } = useFetchUser({ isAnonymous, uid });
  const { name = "", lastname = "" } = user ?? {};
  const t = useI18n();

  return (
    <Styles className="WelcomeHome">
      <p className="WelcomeHome__greeting">
        {t.greetings.profile} {name} {lastname}
      </p>
      <Button
        mode="SECONDARY"
        className="WelcomeHome__button"
        onClick={() => push("/billboard")}
      >
        {t.greetings.billboard}
      </Button>
    </Styles>
  );
};

WelcomeHome.defaultProps = {};

export default WelcomeHome;
