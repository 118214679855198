import styled from "styled-components";

import { HomeStyledProps as Props } from "./Home.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const HomeStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  padding-top: 12rem;

  @media (min-width: ${tablet}px) {
    padding-top: calc(
      var(--sizes-navbar-height-mobile) - env(safe-area-inset-top)
    );
  }

  .Home {
    &__header {
      grid-column: fullBleed;
    }

    &__main {
      flex: 1;
      display: flex;
      flex-direction: column;
      grid-column: content;
      padding-bottom: 4rem;
    }

    &__movies {
      grid-column: fullBleed;
    }

    &__carousel {
      padding-bottom: 0;
    }

    &__download {
      grid-column: content;
      overflow: hidden;

      @media (max-width: ${tablet}px) {
        grid-column: fullBleed;
      }
    }

    &__footer {
      grid-column: fullBleed;
      padding-top: 3.2rem;
    }
  }
`;

export default HomeStyled;
