import React from "react";
import { animated } from "react-spring";

import Styles from "./BillboardPlaceholder.styles";
import { BillboardPlaceholderProps as Props } from "./BillboardPlaceholder.types";
import usePlaceholderAnimation from "hooks/usePlaceholderAnimation";

const BillboardPlaceholder: React.FC<Props> = props => {
  const { className } = props;
  const animationStyle = usePlaceholderAnimation();

  const movieCard = (
    <div className="BillboardPlaceholder__card">
      <animated.div
        className="BillboardPlaceholder__image"
        style={animationStyle}
      />
      <animated.div
        className="BillboardPlaceholder__modality"
        style={animationStyle}
      />
      <animated.div
        className="BillboardPlaceholder__language"
        style={animationStyle}
      />
      <animated.div
        className="BillboardPlaceholder__title"
        style={animationStyle}
      />
    </div>
  );

  return (
    <Styles className={`BillboardPlaceholder ${className}`}>
      {movieCard}
      {movieCard}
      {movieCard}
      {movieCard}
      {movieCard}
      {movieCard}
      {movieCard}
    </Styles>
  );
};

BillboardPlaceholder.defaultProps = {};

export default BillboardPlaceholder;
