import { useFetchFilteredMovies } from "@multicines/services";
import { useFetchMovies } from "@multicines/services";
import { useCountriesStore } from "@multicines/stores";
import { Modality } from "@multicines/types";
import { Banner } from "artisn/types";
import { Image } from "artisn-ui-react";
import AddCartSVG from "images/add-cart.svg";
import { useRouter } from "next/router";
import React, { useMemo, useRef } from "react";

import Styles from "./MovieCarouselBanner.styles";
import { MovieCarouselBannerProps as Props } from "./MovieCarouselBanner.types";
import Button from "components/global/Button/Button";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { sanitizeText } from "utils/seo.utils";

const MovieCarouselBanner: React.FC<Props> = props => {
  const { className, banner } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const analyticsHook = useAnalytics({});
  const { image_url, banner_description, section } = banner;
  const t = useI18n().common;
  const { push } = useRouter();
  const viewRef = useRef(null);
  const selectedCity = useCountriesStore(state => state.selectedCity);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const { data: movies } = useFetchMovies({ isAnonymous, uid }, selectedStore);
  const { data: previewMovies } = useFetchFilteredMovies(
    { isAnonymous, uid },
    "Preview",
    selectedStore
  );

  const { logSelectMoviesBanner } = analyticsHook;

  const groupedMovies = useMemo(() => {
    if (!previewMovies) return;
    return movies?.concat(previewMovies);
  }, [movies, previewMovies]);

  const modality = groupedMovies
    ?.filter(item => item.externalId === section)
    .map(movie => (!movie.modality ? "All" : (movie.modality as Modality)));

  const handleBannerClick = (banner: Banner) => {
    const { banner_description, banner_title, banner_type } = banner;
    const { product_name, vendor_name, banner_category_id } = banner;
    logSelectMoviesBanner({
      banner_category_id:
        banner_category_id ?? "banner without banner category id",
      banner_title: banner_title ?? "banner without banner title",
      banner_description:
        banner_description ?? "banner without banner description",
      banner_type: banner_type ?? "banner without banner type",
      product_name: product_name ?? "banner without product name",
      vendor_name: vendor_name ?? "banner without vendor name"
    });

    if (banner_type === "URL") {
      window.open(banner?.banner_url ?? " ", "_blank");
      return;
    }

    if (banner_type === "CATEGORY") {
      const { category_id, category_name } = banner;
      const sanitizedName = sanitizeText(category_name ?? "");
      const url = category_id
        ? `/categories/${category_id}/${sanitizedName}`
        : "/categories";
      push(url);
      return;
    }

    if (banner_type === "SECTION") {
      if (!selectedCity || !selectedStore) return;

      push(
        `/movie/${section}?modality=${modality}&cityId=${selectedCity.id}&storeId=${selectedStore[0]?.storeId}`
      );
    }
  };

  return (
    <Styles
      onClick={() => handleBannerClick(banner)}
      ref={viewRef}
      className={`MovieCarouselBanner ${className}`}
    >
      <Image
        className="MovieCarouselBanner__images"
        image={image_url}
        alt={banner_description}
      />
      <div className="MovieCarouselBanner__buttons">
        {section ? (
          <Button mode="PRIMARY" onClick={() => handleBannerClick(banner)}>
            <AddCartSVG className="MovieCarouselBanner__buttons__buySvg" />
            <span className="MovieCarouselBanner__buttons__buyText">
              {t.buyTickets}
            </span>
          </Button>
        ) : null}
      </div>
    </Styles>
  );
};

MovieCarouselBanner.defaultProps = {
  className: ""
};

export default MovieCarouselBanner;
