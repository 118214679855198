// useObserveBanners  customHook
import { events } from "artisn/analytics";
import { Banner } from "artisn/types";
import { useCallback, useEffect, useRef } from "react";

import useAnalytics from "./analytics/useAnalytics";
import useTalkShop from "./useTalkShop";
import CONSTANTS from "config/constants";

const { logCustomEvent } = events;
const { PLATFORM } = CONSTANTS.GENERAL;

const useObserveBanners = (
  banners: Banner,
  viewRef: React.RefObject<HTMLElement>,
  event: string,
  once?: boolean
) => {
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const observer = useRef<IntersectionObserver>();
  const itemObserved = viewRef.current;

  const { commonParams, canBeSendAnalytics } = analyticsHook;

  const callback = useCallback<IntersectionObserverCallback>(
    entries => {
      const [entry] = entries;
      const banner = banners;
      const { banner_category_id, banner_description, banner_title } =
        banner ?? {};
      const { product_name, vendor_name, banner_type } = banner ?? {};
      if (!entry.isIntersecting || !canBeSendAnalytics) return;

      const intersectionDistance =
        entry.intersectionRect.right - entry.intersectionRect.left;
      if (entry.intersectionRatio > 0 && intersectionDistance > 0) {
        logCustomEvent(event, {
          ...commonParams,
          platform: PLATFORM ?? "banner without platform",
          banner_category_id:
            banner_category_id ?? "banner without banner category id",
          banner_title: banner_title ?? "banner without banner title",
          banner_description:
            banner_description ?? "banner without banner description",
          banner_type: banner_type ?? "banner without banner type",
          product_name: product_name ?? "banner without product name",
          vendor_name: vendor_name ?? "banner without vendor name"
        });
      }

      if (once && itemObserved) {
        observer.current?.unobserve(itemObserved);
      }
    },
    [banners, canBeSendAnalytics, commonParams, event, itemObserved, once]
  );

  useEffect(() => {
    if (!banners || !itemObserved) return;

    observer.current = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1.0
    });

    observer.current?.observe(itemObserved);

    return () => observer.current?.unobserve(itemObserved);
  }, [banners, callback, itemObserved]);
};

export default useObserveBanners;
