import { Image, Button } from "artisn-ui-react";
import LogoSmallSVG from "images/logo-small.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./MembershipModal.styles";
import { MembershipModalProps as Props } from "./MembershipModal.types";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";

const MembershipModal: React.FC<Props> = props => {
  const { push } = useRouter();

  const analyticsHook = useAnalytics({});
  const t = useI18n().membership.ProfileNoMembership;

  const { logSelectMulticlubLogin } = analyticsHook;

  const buttonHandler = () => {
    logSelectMulticlubLogin();
    push("/profile/membership");
  };

  return (
    <Styles className="MembershipModal">
      <Image
        className="MembershipModal__image"
        image="assets/images/colors-membership.png"
        alt="membership"
      />
      <div className="MembershipModal__box">
        <LogoSmallSVG />
        <div className="MembershipModal__title">
          <h2 className="MembershipModal__text__primary">{t.title}</h2>
        </div>
        <p className="MembershipModal__text__paragraph">{t.instructions}</p>
        <Button className="MembershipModal__button" onClick={buttonHandler}>
          {t.join}
        </Button>
      </div>
    </Styles>
  );
};

MembershipModal.defaultProps = {};

export default MembershipModal;
