import styled from "styled-components";

import { AdsBannerImageStyledProps as Props } from "./AdsBannerImage.types";

const AdsBannerImageStyled = styled.div<Props>`
  .AdsBannerImage {
    &__banner {
      &__image {
        .Image__img {
          cursor: pointer;
          aspect-ratio: 16/9;
          border-radius: 1.6rem;
          object-fit: cover;
        }
      }

      &__read {
        .Image__img {
          cursor: default;
        }
      }
    }
  }
`;

export default AdsBannerImageStyled;
