import { useFetchBanners } from "@multicines/services";
import { SNACKS_VENDOR } from "@multicines/utils";
import { getApiMessageError, getTradeCodeApiError } from "@multicines/utils";
import ArrowLeftSVG from "images/arrow-left-bold-white.svg";
import ArrowRigthSVG from "images/arrow-rigth-bold-white.svg";
import React, { useRef } from "react";
import Carousel from "react-multi-carousel";

import MovieCarouselBanner from "./MovieCarouselBanner/MovieCarouselBanner";
import { responsive } from "./MoviesCarousel.helpers";
import Styles from "./MoviesCarousel.styles";
import { MoviesCarouselProps as Props } from "./MoviesCarousel.types";
import MoviesCarouselPlaceholder from "./MoviesCarouselPlaceholder/MoviesCarouselPlaceholder";
import ErrorState from "components/global/ErrorState/ErrorState";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import { useStoresStore } from "stores/stores/stores.store";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const MoviesCarousel: React.FC<Props> = props => {
  const { className } = props;
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [, snacksStore] = selectedStore ?? [];
  const { storeId, vendor } = snacksStore ?? {};
  const { id: selectedSnackVendorId } = vendor ?? {};
  const { width } = useWindowSize();
  const isMobile = width <= mobile;
  const bannerQuery = useFetchBanners(
    isMobile ? "app_home_carousel_banner" : "carousel_home_banner",
    storeId,
    selectedSnackVendorId ?? SNACKS_VENDOR.id
  );
  const viewRef = useRef(null);

  const { data: banners, status, error, refetch } = bannerQuery;

  const noBanners = banners?.length === 0 || !banners;

  return (
    <Styles ref={viewRef} className={`MoviesCarousel ${className}`}>
      {status === "loading" || (status === "idle" && noBanners) ? (
        <MoviesCarouselPlaceholder className="MoviesCarousel__placeholder" />
      ) : null}

      {status === "error" ? (
        <ErrorState
          title={getTradeCodeApiError(error, "TB")}
          description={getApiMessageError(error)}
          onClick={refetch}
        />
      ) : null}

      {status === "success" ? (
        <Carousel
          containerClass="MoviesCarousel__container"
          itemClass="MoviesCarousel__item"
          swipeable
          draggable
          additionalTransfrom={0}
          autoPlaySpeed={5000}
          showDots={true}
          dotListClass="MoviesCarousel__dots"
          infinite
          arrows={true}
          customLeftArrow={
            <button className="react-multiple-carousel__arrow">
              <ArrowLeftSVG />
            </button>
          }
          customRightArrow={
            <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right">
              <ArrowRigthSVG />
            </button>
          }
          responsive={responsive}
          keyBoardControl
          partialVisible={false}
        >
          {banners?.map((banner, index) => {
            return <MovieCarouselBanner key={index} banner={banner} />;
          })}
        </Carousel>
      ) : null}
    </Styles>
  );
};

MoviesCarousel.defaultProps = {
  className: ""
};

export default MoviesCarousel;
