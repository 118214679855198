import styled from "styled-components";

import { MembershipModalStyledProps as Props } from "./MembershipModal.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const MembershipModalStyled = styled.div<Props>`
  background-color: var(--palette-mid-blue-s15-l20);
  border-radius: 1.6em;

  .MembershipModal {
    &__image {
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: 1.6rem;
    }

    &__box {
      padding: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      margin: 1.6rem 0;
      text-align: center;

      @media (max-width: ${tablet}px) {
        margin: 1.6rem;
      }
    }

    &__button {
      background-color: var(--palette-gray-s0-l40-a40);
      border: 0.2rem solid transparent;
      width: 80%;
      margin: 2.4rem 0;
      padding: 1.6rem;
      border-radius: 1.2rem;
      backdrop-filter: blur(1.6rem);
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.8rem;

      @media (max-width: ${tablet}px) {
        margin-bottom: 0;
      }
    }

    &__text {
      &__primary {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: var(--palette-white);
        text-align: center;
      }

      &__paragraph {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: var(--palette-white);
        text-align: center;

        @media (max-width: ${tablet}px) {
          margin: 0 1.6rem;
        }
      }
    }
  }
`;

export default MembershipModalStyled;
