import styled from "styled-components";

import { MovieCardStyledProps as Props } from "./MovieCard.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const MovieCardStyled = styled.div<Props>`
  width: min-content;
  cursor: ${props => (props.isSelected ? "default" : "pointer")};

  .MovieCard {
    &__icon-image {
      border-radius: 0.4rem;
      margin-bottom: 0.8rem;

      .Image {
        &__img {
          object-fit: fill;
        }
      }
    }

    &__title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        font-size: 1.4rem;
        padding: 0.8rem 0;
      }
    }

    &__description {
      display: flex;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 600;
      margin: 0.8rem 0;

      &__language {
        display: flex;
        color: var(--palette-gray-s0-l80);
        margin-right: 0.4rem;
      }

      &__age {
        margin-left: 0.4rem;
        color: var(--palette-gray-s0-l80);

        &__restricted {
          margin-left: 0.4rem;
          color: var(--palette-red-s95-l65);
        }
      }

      @media (max-width: ${tablet}px) {
        font-size: 1rem;
        margin: 0.4rem 0;
      }
    }

    &__info {
      display: flex;
      padding-top: 0.8rem;
      align-items: center;
    }

    &__genre {
      color: var(--palette-white);
      font-size: 1.4rem;
      font-weight: 500;
      padding: 0.6rem 1.2rem;
      background-color: var(--palette-gray-s0-l40-a40);
      width: fit-content;
      border-radius: 0.6rem;

      @media (max-width: ${tablet - 1}px) {
        padding: 0.4rem 0.8rem;
        font-weight: 500;
      }
    }

    &__points {
      display: flex;
      align-items: center;
      margin-top: 0.4rem;
      padding: 0.4rem 0.8rem;
      width: fit-content;
      border-radius: 50%;
      background-color: var(--palette-gray-s0-l30-a40);

      &-restriction {
        font-size: 1rem;
        font-weight: 700;
        color: var(--palette-red-s95-l65);
      }
    }

    &__image {
      height: 24rem;
      border-radius: 0.4rem;
      aspect-ratio: 2/3;
    }

    &__placeholder {
      color: var(--palette-light-blue-s10-l60);
      font-size: 1.6rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }

    &__duration {
      display: flex;
      padding-top: 0.8rem;
      align-items: center;

      path {
        fill: var(--palette-white);
      }

      &__text {
        font-size: 1.6rem;
        font-weight: 400;
        color: var(--palette-white);
        line-height: 1.6rem;
        padding-left: 0.4rem;

        @media (max-width: ${tablet}px) {
          font-size: 1.4rem;
        }
      }
    }

    &__date {
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: 700;
      color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        margin-right: 1.6rem;
      }
    }
  }
`;

export default MovieCardStyled;
