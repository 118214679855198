import styled from "styled-components";

import { WelcomeHomeStyledProps as Props } from "./WelcomeHome.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const WelcomeHomeStyled = styled.div<Props>`
  padding-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .WelcomeHome {
    &__greeting {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.2rem;
      color: white;

      @media (max-width: ${tablet}px) {
        font-size: 1.6rem;
      }
    }

    &__button {
      padding: 1rem 2rem;
    }
  }
`;

export default WelcomeHomeStyled;
