import Head from "next/head";
import React from "react";

import TabsMenu from "components/global/TabsMenu/TabsMenu";
import Home from "components/home/Home/Home";
import useI18n from "hooks/useI18n";

interface Props {}

// This warning is caused by the use of the styled-components library
const SUPPRESSED_WARNINGS = [
  "styled-components: it looks like an unknown prop",
  "Warning: React does not recognize the",
  "Warning: Received"
];

const HomePage = (props: Props) => {
  const t = useI18n();

  const consoleWarn = console.warn;
  const consoleError = console.error;
  console.warn = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some(entry => `${msg}`?.includes(entry))) {
      consoleWarn(msg, ...args);
    }
  };
  console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some(entry => `${msg}`?.includes(entry))) {
      consoleError(msg, ...args);
    }
  };

  return (
    <>
      <Head>
        <title>{t.home.metaTitle}</title>
        <meta name="description" content={t.home.metaDescription} />
        <meta name="robots" content="index, follow" />
      </Head>
      <TabsMenu>
        <Home />
      </TabsMenu>
    </>
  );
};

export default HomePage;
